import React from "react";

import { SignInPage } from "Authentication";
import SEO from "components/SEO";

const SignInPagePage = () => {
  return <SignInPage path="/" />;
};

export default SignInPagePage;

export const Head = () => <SEO title="Sign In" />;
